.content {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.block1 {
    width: 60%;
} 

.block1>h1 {
    font-weight: lighter;
    text-align: center;
}

.block1>p {
    text-align: center;
}

.block1>div {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
}

.block2 {
    width: 60%;
}

.block2>h1 {
    font-weight: lighter;
}

.block2>h3 {
    font-weight: lighter;
    color: rgba(255, 171, 71, 1);
    /* border: 1px dashed rgba(255, 171, 71, 1); */
}

.block2>p {
    text-align: center;
}

.priceItemsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.blockBeforeAfter {
    width: 60%;
}

.blockBeforeAfter>h1 {
    font-weight: lighter;
}

.blockBeforeAfter>p {
    text-align: center;
}

.block5 {
    width: 60%;
}

.block5>h1 {
    font-weight: lighter;
}

.block5>p {
    text-align: center;
}

.beforeAfterPhotoGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}   

.beforeAfterPhotoGrid>img {
    width: 80%;
    margin: 10%;
    justify-self: center;
}

@media screen and (max-width: 600px) {
    .content {
        font-size: .8em;
    }
    .block1 {
        width: 90vw;
    }

    .block1>h1 {
        text-align: center;
    }

    .block1>p {
        text-align: center;
    }

    .block2 {
        width: 90vw;
    }

    .block3 {
        width: 90vw;
    }
    .block4 {
        width: 90vw;
    }
    .block5 {
        width: 90vw;
    }

    .beforeAfterPhotoGrid {
        display: grid;
        grid-template-columns: 1fr;
    }   

    .priceItemsContainer {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
    }

    .wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0px;
    }
}